import Card from 'components/Card/Card';
import {useMemo, useState} from 'react';
import {YCTable} from '../../components/YCTable/YCTable';
import {download, generateCsv, mkConfig} from 'export-to-csv';
import {Attendee, Player} from 'types/dto';
import {IconButton, useDisclosure} from '@chakra-ui/react';
import {EditIcon} from '@chakra-ui/icons';
import CheckInModal from './CheckinModal';

interface CheckInTableProps {
  data: Player[] | Attendee[] | undefined,
  handleUpdate: (updatedAttendee: Partial<Attendee>) => void;
}

const CheckInTable: React.FC<CheckInTableProps> = ({
                                                     data,
                                                     handleUpdate
                                                   }) => {
  const [search, setSearch] = useState('');
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedAttendee, setSelectedAttendee] = useState<Player | Attendee | undefined>(undefined);


  const columns = useMemo(
    () => [
      {
        Header: 'BKMS ID',
        accessor: 'bkmsId',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Center',
        accessor: 'centerName'
      },
      {
        Header: 'Update',
        accessor: 'id',
        Cell: ({row}: { row: any }) => (
          <IconButton
            aria-label="Edit"
            icon={<EditIcon/>}
            onClick={() => {
              setSelectedAttendee(row.original);
              onOpen();
            }}
          />
        ),
      },
    ],
    [onOpen]
  );


  const exportCheckInDataToCSV = () => {
    const csvConfig = mkConfig({useKeysAsHeaders: true, filename: 'check-in-data'});
    const csvData: any = [];
    if (data != null) {
      data.forEach((attendee) => {
        csvData.push({
          'BKMS ID': attendee.bkmsId,
          'NAME': attendee.name,
          'EMAIL': attendee.email,
          'CENTER': attendee.centerName,
          'CHECKED IN': attendee.present,
          'HOTEL KEY GIVEN': attendee.keyGiven,
          'SMRUTI BHET GIVEN': attendee.smrutiBhetGiven,
          'HOTEL NAME': attendee.hotelName,
          'ROOM NUMBER': attendee.roomNumber
        })
      })
    }
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  }

  return (
    <>
      <Card mt={2}>
        <YCTable columns={columns} data={data || []} search={search} setSearch={setSearch} useFlex
                 downloadAction={exportCheckInDataToCSV}/>
      </Card>
      <CheckInModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          setSelectedAttendee(undefined);
          onClose();
        }}
        eventId={selectedAttendee?.eventId || 0}
        attendeeData={selectedAttendee as Attendee}
        handleUpdate={handleUpdate}/>
    </>
  );
};

export default CheckInTable;
