import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import {
  useCreateGameForEventMutation,
  useGetCourtsForEventQuery,
  useGetLeaguesForEventQuery,
  useGetTeamsForEventQuery
} from '../../app/api';
import React, { useEffect, useState } from 'react';
import { DateTimeInput } from '../../components/DateTimeInput';
import { Game, Team } from '../../types';
import { Input } from '@chakra-ui/react';

interface CreateGameModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  afterSubmit: () => void;
  eventId: number;
}

const CreateGameModal: React.FC<CreateGameModalProps> = ({
                                                           isOpen,
                                                           onClose,
                                                           onOpen,
                                                           afterSubmit,
                                                           eventId,
                                                         }) => {
  const [createGame] = useCreateGameForEventMutation();
  const { data: leagues } = useGetLeaguesForEventQuery(eventId);
  const { data: teams } = useGetTeamsForEventQuery(eventId);
  const { data: courts } = useGetCourtsForEventQuery(eventId);

  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);
  const [selectedLeagueId, setSelectedLeagueId] = useState< number>(-1);

  useEffect(() => {
    if (selectedLeagueId === -1) {
      setFilteredTeams([]);
    } else if (teams && teams.length > 0) {
      const newFilteredTeams: Team[] = teams.filter(
        (team) => team.leagueId === selectedLeagueId
      )
      setFilteredTeams(newFilteredTeams);
    }
  }, [teams, selectedLeagueId]);

  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create Game'}
      initialValues={{
        homeTeamId: '-1',
        awayTeamId: '-1',
        courtId: '-1',
        leagueId: '-1',
        startTime: new Date(),
        endTime: new Date(),
      }}
      inputs={[
        {
          htmlFor: 'leagueId',
          label: 'League',
          type: ({ formik }) => (
            <Input
              as="select"
              id={formik.getFieldProps('leagueId').name}
              {...formik.getFieldProps('leagueId')}
              onChange={(e) => {
                setSelectedLeagueId(parseInt(e.target.value));
                formik.handleChange(e);
              }}
            >
              <option value="-1" disabled>
                Select League
              </option>
              {leagues &&
                leagues.map((league) => (
                  <option key={league.id} value={league.id.toString()}>
                    {league.name}
                  </option>
                ))}
            </Input>
          ),
        },
        {
          htmlFor: 'homeTeamId',
          label: 'Home Team',
          type: ({ formik }) => (
            <Input
              as="select"
              id={formik.getFieldProps('homeTeamId').name}
              {...formik.getFieldProps('homeTeamId')}
            >
              <option value="-1" disabled>
                Select Home Team
              </option>
              {filteredTeams.map((team) => (
                <option key={team.id} value={team.id.toString()}>
                  {team.name}
                </option>
              ))}
            </Input>
          ),
        },
        {
          htmlFor: 'awayTeamId',
          label: 'Away Team',
          type: ({ formik }) => (
            <Input
              as="select"
              id={formik.getFieldProps('awayTeamId').name}
              {...formik.getFieldProps('awayTeamId')}
            >
              <option value="-1" disabled>
                Select Away Team
              </option>
              {filteredTeams.map((team) => (
                <option key={team.id} value={team.id.toString()}>
                  {team.name}
                </option>
              ))}
            </Input>
          ),
        },
        {
          htmlFor: 'courtId',
          label: 'Court',
          type: ({ formik }) => (
            <Input
              as="select"
              id={formik.getFieldProps('courtId').name}
              {...formik.getFieldProps('courtId')}
            >
              <option value="-1" disabled>
                Select Court
              </option>
              {courts &&
                courts.map((court) => (
                  <option key={court.id} value={court.id.toString()}>
                    {court.name}
                  </option>
                ))}
            </Input>
          ),
        },
        {
          htmlFor: 'startTime',
          label: 'Start Time',
          type: ({ formik }) => (
            <DateTimeInput
              date={formik.values.startTime}
              onDateChange={(newDate) => {
                if (!isNaN(newDate.getTime())) {
                  formik.setFieldValue('startTime', newDate);
                }
              }}
            />
          ),
        },
        {
          htmlFor: 'endTime',
          label: 'End Time',
          type: ({ formik }) => (
            <DateTimeInput
              date={formik.values.endTime}
              onDateChange={(newDate) => {
                if (!isNaN(newDate.getTime())) {
                  formik.setFieldValue('endTime', newDate);
                }
              }}
            />
          ),
        },
      ]}
      validationSchema={yup.object().shape({
        homeTeamId: yup
          .string()
          .required('Home Team is required')
          .notOneOf(
            [yup.ref('awayTeamId')],
            'Home Team and Away Team must be different'
          )
          .test(
            'same-league-as-away',
            'Home Team and Away Team must belong to the same league',
            function (value) {
              const { awayTeamId } = this.parent;
              const homeTeam = teams?.find(
                (team) => team.id === parseInt(value!)
              );
              const awayTeam = teams?.find(
                (team) => team.id === parseInt(awayTeamId as string)
              );
              return homeTeam?.leagueId === awayTeam?.leagueId;
            }
          ),
        awayTeamId: yup
          .string()
          .required('Away Team is required')
          .notOneOf(
            [yup.ref('homeTeamId')],
            'Home Team and Away Team must be different'
          ),
        courtId: yup.string().required('Court is required'),
        startTime: yup
          .date()
          .required('Start Time is required')
          .test(
            'is-before-end-time',
            'Start Time must be before End Time',
            function (value) {
              const { endTime } = this.parent;
              return !endTime || value! < endTime;
            }
          ),
        endTime: yup
          .date()
          .required('End Time is required')
          .test(
            'is-after-start-time',
            'End Time must be after Start Time',
            function (value) {
              const { startTime } = this.parent;
              return !startTime || value! > startTime;
            }
          ),
      })}
      onSubmit={async (values, formik) => {
        const vals = values as CreateGameFormValues;
        const game: Partial<Game> = {
          courtId: parseInt(vals.courtId),
          awayTeamId: parseInt(vals.awayTeamId),
          homeTeamId: parseInt(vals.homeTeamId),
          eventId: eventId,
          leagueId: parseInt(vals.leagueId),
          scheduledAt: vals.startTime,
          endTime: vals.endTime,
          startTime: vals.startTime,
          awayTeamScore: 0,
          homeTeamScore: 0,
          played: false,
        };
        await createGame({
          game: game,
        }).unwrap();
        formik.setSubmitting(false);
        onClose();
        afterSubmit();
      }}
    />
  );
};

interface CreateGameFormValues {
  homeTeamId: string;
  awayTeamId: string;
  courtId: string;
  leagueId: string;
  startTime: Date;
  endTime: Date;
}

export default CreateGameModal;
