import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useGetHotelsForEventQuery} from '../../app/api';
import {Attendee, Hotel} from '../../types/dto';

interface CheckInModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  attendeeData?: Attendee;
  eventId: number;
  handleUpdate: (updatedAttendee: Partial<Attendee>) => void;
}

const CheckInModal: React.FC<CheckInModalProps> = ({
                                                     isOpen,
                                                     onOpen,
                                                     onClose,
                                                     attendeeData,
                                                     eventId,
                                                     handleUpdate
                                                   }) => {
  const {data} = useGetHotelsForEventQuery(eventId);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isSmrutiBhetGiven, setIsSmrutiBhetGiven] = useState(false);
  const [isHotelKeyGiven, setIsHotelKeyGiven] = useState(false);
  const [roomNumber, setRoomNumber] = useState(0);
  const [selectedHotel, setSelectedHotel] = useState<number | undefined>(0);

  useEffect(() => {
    if (attendeeData != null) {
      setIsCheckedIn(attendeeData.present || false);
      setIsHotelKeyGiven(attendeeData.keyGiven || false);
      setSelectedHotel(attendeeData.hotelId);
      setRoomNumber(attendeeData.roomNumber || 0);
      setIsSmrutiBhetGiven(attendeeData.smrutiBhetGiven || false);
    }
  }, [attendeeData]);

  const handleCheckboxChange = (checkbox: string) => {
    switch (checkbox) {
      case 'checkedIn':
        setIsCheckedIn(!isCheckedIn);
        break;
      case 'smrutiBhetGiven':
        setIsSmrutiBhetGiven(!isSmrutiBhetGiven);
        break;
      case 'hotelKeyGiven':
        setIsHotelKeyGiven(!isHotelKeyGiven);
        break;
      default:
        break;
    }
  };

  const handleRoomNumberChange = (e: any) => {
    setRoomNumber(e.target.value);
  };

  const handleHotelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedHotel(Number(e.target.value));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Edit Player Status</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <FormControl>
            <FormLabel>Attendee Name</FormLabel>
            <Input type="text" value={attendeeData?.name} isReadOnly/>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>BKMS ID</FormLabel>
            <Input type="text" value={attendeeData?.bkmsId?.toString() || ''} isReadOnly/>
          </FormControl>
          <FormControl mt={4}>
            <Checkbox isChecked={isHotelKeyGiven} onChange={() => handleCheckboxChange('hotelKeyGiven')}>
              Hotel Key Given
            </Checkbox>
          </FormControl>
          <FormControl mt={4}>
            <Checkbox isChecked={isCheckedIn} onChange={() => handleCheckboxChange('checkedIn')}>
              Checked In
            </Checkbox>
          </FormControl>
          <FormControl mt={4}>
            <Checkbox isChecked={isSmrutiBhetGiven} onChange={() => handleCheckboxChange('smrutiBhetGiven')}>
              Smruti Bhet Given
            </Checkbox>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Room Number</FormLabel>
            <Input type="number" value={roomNumber} onChange={handleRoomNumberChange}/>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Select Hotel</FormLabel>
            <Select
              placeholder="Select a hotel"
              value={selectedHotel}
              onChange={handleHotelChange}
            >
              {data && data.map((hotel: Hotel) => (
                <option key={hotel.id} value={hotel.id}>
                  {hotel.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => handleUpdate({
            id: attendeeData?.id,
            present: isCheckedIn,
            smrutiBhetGiven: isSmrutiBhetGiven,
            keyGiven: isHotelKeyGiven,
            hotelId: selectedHotel,
            roomNumber: parseInt(String(roomNumber))
          })}>
            Update
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CheckInModal;
