import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  Court,
  CreateSportEvent,
  DetailedTeam,
  Game,
  GameStats,
  League,
  LeagueEvent,
  LoginResponse,
  Player,
  Schedule,
  SportEvent,
  Team,
} from '../types';
import { RootState } from './store';
import {
  Attendee,
  Center,
  CreateMenuEvent,
  CreatePostResponse,
  CreateSubEvent,
  GameView,
  GetFeedResponse,
  Hotel,
  Menu,
  Organization,
  OrganizationsByUser,
  OrgMember,
  Page,
  PlayerStats,
  Round,
  SportEventConfig,
  SportEventView,
  SubEvent,
  UpdatePlayerStats,
  User,
} from '../types/dto';

const apiUrl = `${
  process.env.REACT_APP_API_URL ||
  (process.env.NODE_ENV === 'production'
    ? 'https://api.yogicup.app/prod'
    : 'http://localhost:8080')
}/api/v2/`;

// Define a service using a base URL and expected endpoints
export const yogiCupApi = createApi({
  reducerPath: 'yogicupApi',
  keepUnusedDataFor: 300,
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, { email: string; password: string }>(
      {
        query: ({ email, password }) => ({
          url: '/user/login',
          method: 'POST',
          body: { email, password },
        }),
      }
    ),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/user/logout',
        method: 'POST',
      }),
    }),
    validate: builder.mutation<boolean, string>({
      query: (token) => ({
        url: '/user/validate',
        method: 'POST',
        body: token,
      }),
    }),
    refresh: builder.mutation<LoginResponse, void>({
      query: () => ({
        url: '/user/refresh',
        method: 'GET',
      }),
    }),
    assumeOrgUser: builder.mutation<LoginResponse, number>({
      query: (orgUserId) => ({
        url: `/user/assume/${orgUserId}`,
        method: 'GET',
      }),
    }),
    getOrganizationsByUserId: builder.query<OrganizationsByUser[], number>({
      query: (userId) => `user/${userId}/organizations`,
    }),
    getOrganizationById: builder.query<Organization, number>({
      query: (id) => `organization/${id}`,
    }),
    getMembersForOrganization: builder.query<
      Page<OrgMember>,
      {
        organizationId: number;
        page?: number;
        pageSize?: number;
        search?: string;
      }
    >({
      query: ({ organizationId, page = 1, pageSize = 10, search }) => ({
        url: `organization/${organizationId}/members`,
        params: { page, pageSize, search },
      }),
    }),
    inviteMemberToOrganization: builder.mutation<
      boolean,
      {
        organizationId: number;
        email: string;
        role: string;
      }
    >({
      query: ({ organizationId, email, role }) => ({
        url: `organization/${organizationId}/members/invite`,
        method: 'POST',
        body: { email, role },
      }),
    }),
    updateMemberForOrganization: builder.mutation<
      boolean,
      {
        organizationId: number;
        userId: number;
        role: string;
      }
    >({
      query: ({ organizationId, userId, role }) => ({
        url: `organization/${organizationId}/members/${userId}`,
        method: 'PUT',
        body: { role },
      }),
    }),
    getEventsForOrganization: builder.query<
      SportEventView[],
      { organizationId: number }
    >({
      query: ({ organizationId }) => `organization/${organizationId}/events`,
    }),
    getAllEvents: builder.query<SportEventView[], void>({
      query: () => `event`,
    }),
    getEventById: builder.query<SportEventView, number>({
      query: (id) => `event/${id}`,
    }),
    getTeamById: builder.query<DetailedTeam, number>({
      query: (id) => `team/${id}`,
    }),
    getGameById: builder.query<GameView, number>({
      query: (id) => `game/${id}`,
    }),
    getPlayerById: builder.query<Player, number>({
      query: (id) => `player/${id}`,
    }),
    getLeagueById: builder.query<League, number>({
      query: (id) => `league/${id}`,
    }),
    getCourtById: builder.query<Court, number>({
      query: (id) => `court/${id}`,
    }),
    getAllTeams: builder.query<Team[], void>({
      query: () => `team`,
    }),
    getAllCourts: builder.query<Court[], void>({
      query: () => `court`,
    }),
    getAllGames: builder.query<Game[], void>({
      query: () => `game`,
    }),
    generateSchedule: builder.mutation<
      Schedule,
      {
        eventId: number;
        timeslots: [Date, Date][];
        gameDuration: number;
        timeBetweenGames: number;
      }
    >({
      query: ({ eventId, ...rest }) => ({
        url: `event/${eventId}/schedule/plan`,
        method: 'POST',
        body: rest,
      }),
    }),
    importSchedule: builder.mutation<
      Schedule,
      { schedule: Schedule; eventId: number }
    >({
      query: ({ schedule, eventId }) => ({
        url: `event/${eventId}/schedule`,
        method: 'POST',
        body: schedule,
      }),
    }),
    saveSchedule: builder.mutation<
      Schedule,
      { schedule: Schedule; eventId: number }
    >({
      query: ({ schedule, eventId }) => ({
        url: `event/${eventId}/schedule/edit`,
        method: 'POST',
        body: schedule,
      }),
    }),
    simulateRegularSeason: builder.mutation<void, { eventId: number }>({
      query: ({ eventId }) => ({
        url: `event/${eventId}/simulate`,
        method: 'POST',
      }),
    }),
    getAllLeagues: builder.query<League[], void>({
      query: () => `league`,
    }),
    getAllPlayers: builder.query<Player[], void>({
      query: () => `player`,
    }),
    getAllAttendees: builder.query<Attendee[], void>({
      query: () => `attendee`,
    }),
    getScheduleForEvent: builder.query<Schedule, number>({
      query: (eventId) => ({
        url: `event/${eventId}/schedule`,
        params: { tz: new Date().getTimezoneOffset() / 60 },
      }),
    }),
    getTeamsForEvent: builder.query<Team[], number>({
      query: (eventId) => `event/${eventId}/teams`,
    }),
    getPlayersForEvent: builder.query<Player[], number>({
      query: (eventId) => `event/${eventId}/players`,
    }),
    getAttendeesForEvent: builder.query<Attendee[], number>({
      query: (eventId) => `event/${eventId}/attendees`,
    }),
    getLeaguesForEvent: builder.query<LeagueEvent[], number>({
      query: (eventId) => `event/${eventId}/leagues`,
    }),
    getCourtsForEvent: builder.query<Court[], number>({
      query: (eventId) => `event/${eventId}/courts`,
    }),
    getGameStats: builder.query<GameStats, number>({
      query: (id) => `game/${id}/stats`,
    }),
    updateTeam: builder.mutation<Team, { id: number; team: Partial<Team> }>({
      query: ({ team, id }) => ({
        url: `team/${id}`,
        method: 'PUT',
        body: team,
      }),
    }),
    updateEvent: builder.mutation<
      SportEventView,
      {
        id: number;
        event: Partial<SportEvent>;
        config: Partial<SportEventConfig>;
      }
    >({
      query: ({ id, event, config }) => ({
        url: `event/${id}`,
        method: 'PUT',
        body: { event, config },
      }),
    }),
    checkInPlayer: builder.mutation<
      boolean,
      { id: number; isPresent: boolean }
    >({
      query: ({ id, isPresent }) => ({
        url: `player/${id}/checkin`,
        method: 'PUT',
        body: { isPresent },
      }),
    }),
    startGame: builder.mutation<Game, { id: number }>({
      query: ({ id }) => ({
        url: `game/${id}/start`,
        method: 'POST',
      }),
    }),
    finishGame: builder.mutation<Game, { id: number }>({
      query: ({ id }) => ({
        url: `game/${id}/finish`,
        method: 'POST',
      }),
    }),
    verifyGame: builder.mutation<Game, { id: number }>({
      query: ({ id }) => ({
        url: `game/${id}/verify`,
        method: 'POST',
      }),
    }),
    uploadEventData: builder.mutation<
      { fileName: string },
      { eventId: number; file: FormData }
    >({
      query: ({ file, eventId }) => ({
        url: `/event/${eventId}/upload`,
        method: 'POST',
        body: file,
      }),
    }),
    processEventData: builder.mutation<
      boolean,
      { eventId: number; fileName: string }
    >({
      query: ({ eventId, fileName }) => ({
        url: `/event/${eventId}/process/${fileName}`,
        method: 'GET',
      }),
    }),
    upsertCourts: builder.mutation<
      Court[],
      { courts: Partial<Court>[]; eventId: number }
    >({
      query: ({ courts, eventId }) => ({
        url: `event/${eventId}/courts`,
        method: 'POST',
        body: courts,
      }),
    }),
    updatePlayerStats: builder.mutation<
      PlayerStats,
      { id: number; stats: UpdatePlayerStats }
    >({
      query: ({ stats, id }) => ({
        url: `scoring/${id}`,
        method: 'PUT',
        body: stats,
      }),
    }),
    updateLeagueEvent: builder.mutation<
      LeagueEvent,
      { eventId: number; leagueId: number; leagueEvent: Partial<LeagueEvent> }
    >({
      query: ({ leagueEvent, leagueId, eventId }) => ({
        url: `event/${eventId}/league/${leagueId}`,
        method: 'PUT',
        body: leagueEvent,
      }),
    }),
    createEvent: builder.mutation<SportEvent, CreateSportEvent>({
      query: (event) => ({
        url: `event`,
        method: 'POST',
        body: event,
      }),
    }),
    updateGame: builder.mutation<Game, { id: number; game: Partial<Game> }>({
      query: ({ game, id }) => ({
        url: `game/${id}`,
        method: 'PUT',
        body: game,
      }),
    }),
    rankTeams: builder.mutation<Team[], { eventId: number; leagueId: number }>({
      query: ({ eventId, leagueId }) => ({
        url: `event/${eventId}/teams/${leagueId}/ranking`,
        method: 'GET',
      }),
    }),
    getRankings: builder.mutation<
      Team[],
      { eventId: number; leagueId: number; refresh: boolean }
    >({
      query: ({ eventId, leagueId, refresh }) => ({
        method: 'GET',
        url: `event/${eventId}/teams/${leagueId}/ranking`,
        params: { refresh },
      }),
    }),
    generatePlayoffSchedule: builder.mutation<
      Round[],
      {
        eventId: number;
        leagueId: number;
        numSeeds?: number;
        persist?: boolean;
        doubleElimination?: boolean;
        date: Date;
      }
    >({
      query: ({
        eventId,
        leagueId,
        numSeeds,
        persist,
        doubleElimination,
        date,
      }) => ({
        url: `event/${eventId}/playoffs/${leagueId}/schedule/new`,
        method: 'GET',
        params: { numSeeds, persist, date, doubleElimination },
      }),
    }),
    getPlayoffSchedule: builder.mutation<
      Round[],
      { eventId: number; leagueId: number }
    >({
      query: ({ eventId, leagueId }) => ({
        url: `event/${eventId}/playoffs/${leagueId}/schedule`,
        method: 'GET',
      }),
    }),
    deletePlayoffSchedule: builder.mutation<
      void,
      { eventId: number; leagueId: number }
    >({
      query: ({ eventId, leagueId }) => ({
        url: `event/${eventId}/playoffs/${leagueId}/schedule`,
        method: 'DELETE',
      }),
    }),
    getFeed: builder.query<
      GetFeedResponse,
      { eventId: number; cursor?: number; approved?: boolean }
    >({
      query: ({ eventId, cursor, approved }) => {
        return {
          url: `feed/${eventId}`,
          params: { cursor, approved },
        };
      },
    }),
    createPost: builder.mutation<
      CreatePostResponse,
      {
        eventId: number;
        title: string;
        description: string;
        fileType: string;
        fileName: string;
        timestamp: string;
      }
    >({
      query: ({
        eventId,
        fileType,
        title,
        description,
        timestamp,
        fileName,
      }) => {
        return {
          url: `feed/${eventId}/media`,
          method: 'POST',
          body: { title, description, timestamp, fileType, fileName },
        };
      },
    }),
    makePostDecision: builder.mutation<
      boolean,
      { eventId: number; postId: string; approved: boolean }
    >({
      query: ({ eventId, postId, approved }) => {
        return {
          url: `feed/${eventId}/post/${postId}/${
            approved ? 'approve' : 'reject'
          }`,
          method: 'POST',
        };
      },
    }),
    deletePost: builder.mutation<boolean, { eventId: number; postId: string }>({
      query: ({ eventId, postId }) => {
        return {
          url: `feed/${eventId}/post/${postId}`,
          method: 'DELETE',
        };
      },
    }),
    getUsers: builder.query<User[], { eventId: number; playing: boolean }>({
      query: ({ eventId, playing }) => ({
        url: `event/${eventId}/users`,
        params: { playing },
      }),
    }),
    getCenters: builder.query<Center[], void>({
      query: () => `center`,
    }),
    createTeam: builder.mutation<
      Team,
      {
        eventId: number;
        centerId: number;
        leagueId: number;
        players: number[];
        name: string;
      }
    >({
      query: ({ eventId, centerId, leagueId, players, name }) => ({
        url: `team`,
        method: 'POST',
        body: { eventId, centerId, leagueId, users: players, name },
      }),
    }),
    exportSchedule: builder.mutation<any, number>({
      query: (eventId) => ({
        url: `event/${eventId}/games/export`,
        method: 'GET',
        responseHandler: async (response) => {
          return window.URL.createObjectURL(await response.blob());
        },
        cache: 'no-cache',
      }),
    }),
    requestPasswordReset: builder.mutation<void, string>({
      query: (email) => ({
        url: `user/reset`,
        method: 'GET',
        params: { email },
      }),
    }),
    changePassword: builder.mutation<
      boolean,
      { password: string; confirmPassword: string; token: string }
    >({
      query: ({ password, confirmPassword, token }) => ({
        url: `user/confirmReset`,
        method: 'POST',
        body: { password, confirmPassword, token },
      }),
    }),
    getSubEventsForEvent: builder.query<SubEvent[], number>({
      query: (eventId) => `event/${eventId}/subevent`,
    }),
    getSubEventsForEventById: builder.query<
      SubEvent,
      { eventId: number; subEventId: number }
    >({
      query: ({ eventId, subEventId }) =>
        `event/${eventId}/subevent/${subEventId}`,
    }),
    deleteSubEvent: builder.mutation<
      boolean,
      { eventId: number; subEventId: number }
    >({
      query: ({ eventId, subEventId }) => {
        return {
          url: `event/${eventId}/subevent/${subEventId}`,
          method: 'DELETE',
        };
      },
    }),
    updateSubEvent: builder.mutation<
      SubEvent,
      { eventId: number; subEventId: number; subEvent: Partial<SubEvent> }
    >({
      query: ({ subEvent, subEventId, eventId }) => ({
        url: `event/${eventId}/subevent/${subEventId}`,
        method: 'PUT',
        body: subEvent,
      }),
    }),
    createSubEvent: builder.mutation<
      SubEvent,
      {
        createSubEvent: CreateSubEvent;
        eventId: number;
      }
    >({
      query: ({ createSubEvent, eventId }) => ({
        url: `/event/${eventId}/subevent`,
        method: 'POST',
        body: createSubEvent,
      }),
    }),
    createSubEventInBulk: builder.mutation<
      SubEvent[],
      {
        subEvents: SubEvent[];
        eventId: number;
      }
    >({
      query: ({ subEvents, eventId }) => ({
        url: `/event/${eventId}/subevent/create-bulk`,
        method: 'POST',
        body: subEvents,
      }),
    }),
    getMenusByEventId: builder.query<Menu[], number>({
      query: (eventId) => `/event/${eventId}/food`,
    }),
    createMenuForEvent: builder.mutation<
      Menu,
      { eventId: number; menu: CreateMenuEvent }
    >({
      query: ({ eventId, menu }) => ({
        url: `/event/${eventId}/food`,
        method: 'POST',
        body: menu,
      }),
    }),
    getMenuById: builder.query<Menu, { eventId: number; menuId: number }>({
      query: ({ eventId, menuId }) => `/event/${eventId}/food/${menuId}`,
    }),
    updateMenuForEvent: builder.mutation<
      Menu,
      { eventId: number; menuId: number; updatedMenu: Partial<Menu> }
    >({
      query: ({ eventId, menuId, updatedMenu }) => ({
        url: `/event/${eventId}/food/${menuId}`,
        method: 'POST',
        body: updatedMenu,
      }),
    }),
    deleteMenuForEvent: builder.mutation<
      Menu,
      { eventId: number; menuId: number }
    >({
      query: ({ eventId, menuId }) => ({
        url: `/event/${eventId}/food/${menuId}`,
        method: 'DELETE',
      }),
    }),
    createGameForEvent: builder.mutation<Game, { game: Partial<Game> }>({
      query: ({ game }) => ({
        url: `game`,
        method: 'POST',
        body: game,
      }),
    }),
    getRegularSeasonGamesForEvent: builder.query<Game[], number>({
      query: (eventId) => `/game/season/${eventId}`,
    }),
    updatePlayerById: builder.mutation<
      Player,
      { playerId: number; player: Partial<Player> }
    >({
      query: ({ playerId, player }) => ({
        url: `/player/${playerId}`,
        method: 'PUT',
        body: player,
      }),
    }),
    updateAttendeeById: builder.mutation<
      Attendee,
      { attendeeId: number; attendee: Partial<Attendee> }
    >({
      query: ({ attendeeId, attendee }) => ({
        url: `/attendee/${attendeeId}`,
        method: 'PUT',
        body: attendee,
      }),
    }),
    getHotelsForEvent: builder.query<Hotel[], number>({
      query: (eventId) => `/event/${eventId}/hotels`,
    }),
  }),
});

export const {
  useGetOrganizationsByUserIdQuery,
  useGetOrganizationByIdQuery,
  useGetEventsForOrganizationQuery,
  useGetAllEventsQuery,
  useGetEventByIdQuery,
  useGetAllTeamsQuery,
  useGetAllCourtsQuery,
  useGetAllGamesQuery,
  useGetAllLeaguesQuery,
  useGetAllPlayersQuery,
  useGetScheduleForEventQuery,
  useGetGameStatsQuery,
  useCreateEventMutation,
  useGetTeamsForEventQuery,
  useGetPlayersForEventQuery,
  useGetLeaguesForEventQuery,
  useGetCourtsForEventQuery,
  useGetTeamByIdQuery,
  useGetGameByIdQuery,
  useLazyGetGameByIdQuery,
  useGetPlayerByIdQuery,
  useGetLeagueByIdQuery,
  useGetCourtByIdQuery,
  useUpdatePlayerStatsMutation,
  useUpdateTeamMutation,
  useLoginMutation,
  useLogoutMutation,
  useValidateMutation,
  useRefreshMutation,
  useAssumeOrgUserMutation,
  useUpsertCourtsMutation,
  useStartGameMutation,
  useVerifyGameMutation,
  useUploadEventDataMutation,
  useProcessEventDataMutation,
  useUpdateLeagueEventMutation,
  useGenerateScheduleMutation,
  useSaveScheduleMutation,
  useImportScheduleMutation,
  useFinishGameMutation,
  useGeneratePlayoffScheduleMutation,
  useUpdateGameMutation,
  useGetRankingsMutation,
  useGetPlayoffScheduleMutation,
  useUpdateEventMutation,
  useGetFeedQuery,
  useCreatePostMutation,
  useMakePostDecisionMutation,
  useDeletePostMutation,
  useGetUsersQuery,
  useGetCentersQuery,
  useCreateTeamMutation,
  useExportScheduleMutation,
  useRequestPasswordResetMutation,
  useChangePasswordMutation,
  useGetMembersForOrganizationQuery,
  useInviteMemberToOrganizationMutation,
  useUpdateMemberForOrganizationMutation,
  useGetSubEventsForEventQuery,
  useGetSubEventsForEventByIdQuery,
  useCreateSubEventMutation,
  useCreateSubEventInBulkMutation,
  useDeleteSubEventMutation,
  useUpdateSubEventMutation,
  useCheckInPlayerMutation,
  useGetAttendeesForEventQuery,
  useGetMenusByEventIdQuery,
  useUpdateMenuForEventMutation,
  useCreateMenuForEventMutation,
  useGetMenuByIdQuery,
  useDeleteMenuForEventMutation,
  useCreateGameForEventMutation,
  useGetRegularSeasonGamesForEventQuery,
  useUpdateAttendeeByIdMutation,
  useUpdatePlayerByIdMutation,
  useGetHotelsForEventQuery,
  useSimulateRegularSeasonMutation,
  useDeletePlayoffScheduleMutation,
} = yogiCupApi;

export const {
  endpoints: { login, validate, refresh, assumeOrgUser },
} = yogiCupApi;
