import {Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useToast} from '@chakra-ui/react';
import Page from '../../components/Page/Page';
import Card from 'components/Card/Card';
import CheckInTable from './CheckInTable';
import {useEventContext} from '../../contexts/EventContext';
import {
  useGetAttendeesForEventQuery,
  useGetPlayersForEventQuery,
  useUpdateAttendeeByIdMutation,
  useUpdatePlayerByIdMutation
} from '../../app/api';
import {skipToken} from '@reduxjs/toolkit/query';
import {Attendee, Player} from '../../types/dto';

const CheckIn = ({}) => {

  const {currentEvent} = useEventContext();
  const {data: playerData, refetch: refetchPlayerData} = useGetPlayersForEventQuery(currentEvent ? currentEvent.id : skipToken);
  const {data: attendeeData, refetch: refetchAttendeeData} = useGetAttendeesForEventQuery(currentEvent ? currentEvent.id : skipToken);
  const [updateAttendee] = useUpdateAttendeeByIdMutation();
  const [updatePlayer] = useUpdatePlayerByIdMutation();
  const toast = useToast();

  const handleAttendeeUpdate = (updatedAttendee: Partial<Attendee>) => {
    updateAttendee({attendeeId: updatedAttendee.id!, attendee: updatedAttendee})
      .then(() => {
        toast({
          title: 'Success',
          description: 'Update Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        refetchAttendeeData();
      })
      .catch((e) => console.error(e));
  }


  const handlePlayerUpdate = (updatedPlayer: Partial<Attendee>) => {
    updatePlayer({playerId: updatedPlayer.id!, player: updatedPlayer as Player})
      .then(() => {
        toast({
          title: 'Success',
          description: 'Update Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        refetchPlayerData();
      })
      .catch((e) => console.error(e));
  }

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Check-In</Heading>
          </Card>
          <Card>
            <Tabs>
              <TabList>
                <Tab>Attendee Check-In</Tab>
                <Tab>Player Check-In</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <CheckInTable data={attendeeData != null ? attendeeData : []} handleUpdate={handleAttendeeUpdate}/>
                </TabPanel>
                <TabPanel>
                  <CheckInTable data={playerData != null ? playerData : []} handleUpdate={handlePlayerUpdate}/>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </Box>
      </Page>
    </>
  );
};

export default CheckIn;
