import {
  PlayoffBracketProps,
  SingleEliminationPlayoffBracket,
} from './SingleEliminationPlayoffBracket';
import { Round } from '../../types/dto';
import { Box, Flex, Heading } from '@chakra-ui/react';

export const DoubleEliminationPlayoffBracket: React.FC<PlayoffBracketProps> = ({
  schedule,
  teams,
}) => {
  const getWinnersBracket = (allRounds: Round[]) => {
    const winnersBracket: Round[] = [];
    let roundId = 1;
    allRounds.forEach((round) => {
      const validGames = round.games.filter(
        (game) => game.nextGameIdForLoser != null || game.nextGameId == null
      );
      if (validGames.length > 0) {
        winnersBracket.push({
          id: roundId,
          title: `Round ${roundId}`,
          games: validGames,
        });
        roundId += 1;
      }
    });
    return winnersBracket;
  };

  const getLosersBracket = (allRounds: Round[]) => {
    const losersBracket: Round[] = [];
    let roundId = 1;
    allRounds.forEach((round) => {
      const validGames = round.games.filter(
        (game) => game.nextGameIdForLoser == null && game.nextGameId != null
      );
      if (validGames.length > 0) {
        losersBracket.push({
          id: roundId,
          title: `Round ${roundId}`,
          games: validGames,
        });
        roundId += 1;
      }
    });
    return losersBracket;
  };
  const winnersBracket: Round[] = getWinnersBracket(schedule);
  const losersBracket: Round[] = getLosersBracket(schedule);
  return (
    <Flex direction="column" align="center" w="100%">
      <Box mb={4} w="100%">
        <Heading as="h1" mb={2}>
          Winner's Bracket
        </Heading>
        <SingleEliminationPlayoffBracket
          schedule={winnersBracket}
          teams={teams}
        />
      </Box>

      <Box w="100%">
        <Heading as="h1" mb={2}>
          Loser's Bracket
        </Heading>
        <SingleEliminationPlayoffBracket
          schedule={losersBracket}
          teams={teams}
        />
      </Box>
    </Flex>
  );
};
