import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Center,
  DarkMode,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Text,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { AiFillHome } from 'react-icons/ai';
import { BiBasketball } from 'react-icons/all';
import {
  BsCheckCircle,
  BsFillCalendarFill,
  BsFillPeopleFill,
  BsListTask,
} from 'react-icons/bs';
import { GiBasketballBasket, GiBasketballJersey } from 'react-icons/gi';
import { IoMdImages } from 'react-icons/io';
import { RiMenuFill, RiTeamFill } from 'react-icons/ri';
import {
  Link as RouterLink,
  useLocation,
  useMatch,
  useParams,
} from 'react-router-dom';
import { EventRoleType } from 'types/dto';
import { useAppSelector } from '../../app/store';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { useEventContext } from '../../contexts/EventContext';

const DefaultLinkItems: LinkItemProps[] = [
  {
    name: 'Landing',
    to: '/',
    icon: AiFillHome,
  },
];

const EventLinkItems: LinkItemProps[] = [
  {
    name: 'Event Home',
    to: '',
    icon: AiFillHome,
    requiresRole: 'EVENT_ADMIN',
  },
  {
    name: 'Schedule',
    icon: BsFillCalendarFill,
    to: `schedule`,
  },
  {
    name: 'Menu',
    icon: RiMenuFill,
    to: `menu`,
    requiresRole: `EVENT_ADMIN`,
  },
  {
    name: 'Sub Events',
    icon: BsListTask,
    to: `subevents`,
    requiresRole: 'EVENT_ADMIN',
  },
  {
    name: 'Games',
    icon: BiBasketball,
    to: `games/season`,
    requiresRole: 'EVENT_ADMIN',
  },
  {
    name: 'Teams',
    icon: BsFillPeopleFill,
    to: `teams`,
    requiresRole: 'EVENT_ADMIN',
  },
  {
    name: 'Players',
    icon: GiBasketballJersey,
    to: `players`,
    requiresRole: 'EVENT_ADMIN',
  },
  {
    name: 'Leagues',
    icon: RiTeamFill,
    to: 'leagues',
    requiresRole: 'LEAGUE_ADMIN',
  },
  {
    name: 'Courts',
    icon: (props) => GiBasketballBasket({ ...props, size: 20 }),
    to: `courts`,
    requiresRole: 'EVENT_ADMIN',
  },
  {
    name: 'Check In',
    icon: BsCheckCircle,
    to: `check-in`,
    requiresRole: 'EVENT_STAFF',
  },
  {
    name: 'Feed',
    icon: IoMdImages,
    to: `feed`,
    requiresRole: 'EVENT_ADMIN',
  },
];

interface LinkItemProps {
  name: string;
  to: string;
  icon: IconType;
  requiresRole?: EventRoleType;
}

export const EventLinks: React.FC<{
  eventId?: number;
  links: LinkItemProps[];
}> = ({ links, eventId }) => {
  const { eventId: paramEventId } = useParams();
  let eventIdToUse = eventId ?? paramEventId;
  const user = useAppSelector((store) => store.user);
  const { logout } = useAuth();
  const { toggleColorMode, colorMode } = useColorMode();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const matches = useMatch('/events/:eventId/');

  const baseColor = useColorModeValue('orange', 'blue');

  const bgColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.300');
  return (
    <>
      {links.map(({ name, icon, to }) => (
        <Center key={name} h="full">
          <Link
            h="full"
            key={name}
            _hover={{}}
            as={RouterLink}
            to={matches?.pathname ? to : `../${to}`}
          >
            <HStack
              h="100%"
              p={1}
              color="white"
              _hover={{ background: bgColor }}
              borderRadius="sm"
            >
              {isLargerThan768 ? (
                <>
                  <Icon as={icon}></Icon>
                  <Text size="xl">{name}</Text>
                </>
              ) : (
                <DarkMode>
                  <IconButton icon={icon({})} aria-label={'icon'} />
                </DarkMode>
              )}
            </HStack>
          </Link>
        </Center>
      ))}
      <Center key="mode-switcher-button" h="full">
        <Menu>
          <MenuButton>
            <Avatar
              size="sm"
              name={`${user.user?.firstName} ${user.user?.lastName}`}
            />
          </MenuButton>
          <MenuList display="flex" flexDirection="column">
            <Button
              onClick={toggleColorMode}
              variant="ghost"
              colorScheme={baseColor}
            >
              {colorMode === 'dark' ? 'Light Mode' : 'Dark Mode'}
            </Button>
            <Button
              onClick={() => {
                logout();
              }}
              variant="ghost"
              colorScheme={baseColor}
            >
              Logout
            </Button>
          </MenuList>
        </Menu>
      </Center>
    </>
  );
};

export const NavBar: React.FC<{ pageContainerProps?: BoxProps }> = ({
  children,
  pageContainerProps,
}) => {
  const { currentEvent, canPerformEventAction } = useEventContext();
  const userStore = useAppSelector((store) => store.user);
  const baseColor = useColorModeValue('orange', 'blue');
  const location = useLocation();
  const paths = location.pathname
    .split('/')
    .filter((path) => ![''].includes(path));

  return (
    <Box minH="100vh" bg={useColorModeValue('white', 'gray.800')}>
      <Box
        bg={`${baseColor}.500`}
        borderBottom="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        h={16}
        px={4}
        w="full"
      >
        <HStack spacing={{ base: 1, md: 2 }} h="100%" justify="space-around">
          <HStack>
            <Heading color={'white'} noOfLines={1}>
              Yogi Cup
            </Heading>

            {/* {paths.length > 1 && (
              <Breadcrumb
                fontSize="sm"
                marginTop="0 !important"
                py={0}
                noOfLines={1}
              >
                {paths.map((path, index, paths) => (
                  <BreadcrumbItem key={path}>
                    <BreadcrumbLink
                      as={Link}
                      to={
                        path === 'events'
                          ? '/'
                          : `/${paths.slice(0, index + 1).join('/')}`
                      }
                    >
                      {path.charAt(0).toUpperCase() + path.slice(1)}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            )} */}
          </HStack>
          <Spacer />
          <EventLinks
            eventId={currentEvent?.id}
            links={
              currentEvent
                ? EventLinkItems.filter((link) =>
                    canPerformEventAction(link.requiresRole || 'PLAYER')
                  )
                : DefaultLinkItems
            }
          />
        </HStack>
      </Box>
      <Box p={2} {...pageContainerProps}>
        {children}
      </Box>
    </Box>
  );
};
