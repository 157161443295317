import {
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import React from 'react';
import { Cell, Row } from 'react-table';
import Card from '../../../components/Card/Card';
import { YCTable } from '../../../components/YCTable/YCTable';
import { PlayerStats } from '../../../types';
import { OverrideScoreFieldInput } from './OverrideScoreFieldInput';

export interface TeamScoringPanelProps {
  overriding?: boolean;
  editable?: boolean;
  teamName: string;
  players: PlayerStats[];
  setSelectedPlayer: (player: PlayerStats) => void;
  triggerRefetch?: () => void;
}

export const TeamScoringPanel: React.FC<TeamScoringPanelProps> = ({
  teamName,
  players,
  setSelectedPlayer,
  overriding,
  editable,
  triggerRefetch = () => {},
}) => {
  const totalPoints = players.reduce(
    (acc, player) =>
      acc + player.twoPoints * 2 + player.threePoints * 3 + player.onePoints,
    0
  );
  const totalAssists = players.reduce((acc, player) => acc + player.assists, 0);
  const totalRebounds = players.reduce(
    (acc, player) => acc + player.rebounds,
    0
  );
  const totalSteals = players.reduce((acc, player) => acc + player.steals, 0);
  const totalBlocks = players.reduce((acc, player) => acc + player.blocks, 0);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (cell: Cell<PlayerStats>) => (
          <Input
            w={{ base: '100%', md: '90%' }}
            onClick={(e) => e.stopPropagation()}
            placeholder={cell.value}
          />
        ),
      },
      {
        Header: 'FT',
        accessor: 'onePoints',
      },
      {
        Header: '2PT',
        accessor: 'twoPoints',
      },
      {
        Header: '3PT',
        accessor: 'threePoints',
      },
      {
        Header: 'Assists',
        accessor: 'assists',
      },
      {
        Header: 'Rebounds',
        accessor: 'rebounds',
      },
      {
        Header: 'Steals',
        accessor: 'steals',
      },
      {
        Header: 'Blocks',
        accessor: 'blocks',
      },
      // {
      //   Header: 'Fouls',
      //   accessor: 'fouls',
      // },
    ],
    []
  );

  const overridableColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Free Throw',
        accessor: 'onePoints',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      {
        Header: '2PT',
        accessor: 'twoPoints',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      {
        Header: '3PT',
        accessor: 'threePoints',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      {
        Header: 'Assists',
        accessor: 'assists',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      {
        Header: 'Rebounds',
        accessor: 'rebounds',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      {
        Header: 'Steals',
        accessor: 'steals',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      {
        Header: 'Blocks',
        accessor: 'blocks',
        Cell: (cell: Cell<PlayerStats>) => (
          <OverrideScoreFieldInput
            cell={cell}
            triggerRefetch={triggerRefetch}
          />
        ),
      },
      // {
      //   Header: 'Fouls',
      //   accessor: 'fouls',
      //   Cell: (cell: Cell<PlayerStats>) => (
      //     <OverrideScoreFieldInput
      //       cell={cell}
      //       triggerRefetch={triggerRefetch}
      //     />
      //   ),
      // },
    ],
    []
  );

  return (
    <Card>
      <Heading size="md">{teamName}</Heading>
      <StatGroup>
        <Stat>
          <StatLabel>Points</StatLabel>
          <StatNumber>{totalPoints}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Assists</StatLabel>
          <StatNumber>{totalAssists}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Rebounds</StatLabel>
          <StatNumber>{totalRebounds}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Steals</StatLabel>
          <StatNumber>{totalSteals}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Blocks</StatLabel>
          <StatNumber>{totalBlocks}</StatNumber>
        </Stat>
      </StatGroup>
      <YCTable
        onRowClick={({ original }: Row<PlayerStats>) => {
          if (editable && !overriding) {
            setSelectedPlayer(original);
          }
        }}
        columns={overriding ? overridableColumns : columns}
        data={players}
      />
    </Card>
  );
};
