import { ChakraProvider } from '@chakra-ui/react';
import {
  OrganizationContextProvider,
  RequireOrganization,
} from 'contexts/OrganizationContext';
import Forbidden from 'pages/Forbidden';
import { ForgotPassword } from 'pages/login/ForgotPassword';
import { ResetPassword } from 'pages/login/ResetPassword';
import { OrganizationManagement } from 'pages/organizations/OrganizationManagement';
import { Organizations } from 'pages/organizations/Organizations';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import './App.css';
import { store } from './app/store';
import { Notifications } from './components/Notifications';
import {
  AuthProvider,
  RequireAccess,
  RequireAuth,
} from './contexts/AuthContext/AuthContext';
import { EventContextProvider, RequireEvent } from './contexts/EventContext';
import {
  Courts,
  GameScheduleView,
  Landing,
  Leagues,
  Login,
  RegularSeasonGames,
  SportEvents,
  Teams,
} from './pages';
import CheckIn from './pages/checkin/CheckIn';
import { CourtViewPage } from './pages/courts/CourtViewPage';
import { InitializeSportEventPage } from './pages/events/InitializeSportEventPage/InitializeSportEventPage';
import SportEventPage from './pages/events/SportEventPage/SportEventPage';
import { FeedPage } from './pages/feed/FeedPage';
import AllGames from './pages/games/AllGames';
import { Scoring } from './pages/games/Scoring';
import { ScoringV2 } from './pages/games/ScoringV2';
import Menu from './pages/menu/Menu';
import { MenuViewPage } from './pages/menu/MenuViewPage';
import NotFound from './pages/NotFound';
import Players from './pages/players/Players';
import PlayerViewPage from './pages/players/PlayerViewPage';
import SubEvents from './pages/subevents/SubEvents';
import { SubEventViewPage } from './pages/subevents/SubEventViewPage';
import TeamViewPage from './pages/teams/TeamViewPage';
import { customTheme } from './theme';

export const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={customTheme}>
        <AuthProvider>
          <Router>
            <OrganizationContextProvider>
              <EventContextProvider>
                <Routes>
                  <Route path="login" element={<Login />} />
                  <Route path="forgot" element={<ForgotPassword />} />
                  <Route path="reset" element={<ResetPassword />} />
                  <Route element={<RequireAuth />}>
                    <Route
                      path=""
                      element={<Navigate to={'/organizations'} />}
                    />
                    <Route path="organizations" element={<Organizations />} />
                    <Route
                      path="/organizations/:orgId/*"
                      element={<RequireOrganization />}
                    >
                      <Route path="" element={<OrganizationManagement />} />
                      <Route path="leagues" element={<Leagues />} />
                      <Route path="events" element={<Landing />} />
                      <Route
                        path="events/:eventId/*"
                        element={<RequireEvent />}
                      >
                        <Route path="schedule" element={<GameScheduleView />} />
                        <Route path="check-in" element={<CheckIn />} />
                        {/* Requires ScoreKeeper */}
                        <Route
                          element={<RequireAccess requires={'SCORE_KEEPER'} />}
                        >
                          <Route path="scoring/:gameId" element={<Scoring />} />
                          <Route
                            path="scoringv2/:gameId"
                            element={<ScoringV2 />}
                          />
                        </Route>
                        {/* Requires Event Admin */}
                        <Route
                          element={
                            <RequireAccess
                              requires={'EVENT_ADMIN'}
                              fallbackRoute="schedule"
                            />
                          }
                        >
                          <Route path="" element={<SportEventPage />} />
                          <Route path="events" element={<SportEvents />} />
                          <Route path="teams" element={<Teams />} />
                          <Route path="courts" element={<Courts />} />
                          <Route path="games" element={<AllGames />} />
                          <Route
                            path="games/season"
                            element={<RegularSeasonGames />}
                          />
                          <Route path="feed" element={<FeedPage />} />
                          <Route path="players" element={<Players />} />
                          <Route path="leagues" element={<Leagues />} />
                          <Route path="menu" element={<Menu />} />
                          <Route path="menu/:id" element={<MenuViewPage />} />
                          <Route path="subevents" element={<SubEvents />} />
                          <Route
                            path="subevents/:id"
                            element={<SubEventViewPage />}
                          />
                          <Route path="teams/:id" element={<TeamViewPage />} />
                          <Route
                            path="players/:id"
                            element={<PlayerViewPage />}
                          />
                          <Route
                            path="courts/:id"
                            element={<CourtViewPage />}
                          />
                        </Route>
                        <Route
                          element={<RequireAccess requires={'EVENT_OWNER'} />}
                        >
                          <Route
                            path="initialize"
                            element={<InitializeSportEventPage />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="forbidden" element={<Forbidden />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Notifications />
              </EventContextProvider>
            </OrganizationContextProvider>
          </Router>
        </AuthProvider>
      </ChakraProvider>
    </Provider>
  );
};
